import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Este cursul care ajută pe credincios să descopere valoarea evanghelizării personale, să-și descopere chemarea în slujire și să dea la o parte piedicile în a face voia lui Dumnezeu.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Poți învăța să-ți prezinți eficient mărturia personală, să prezinți pe Hristos în mod practic și să ajuți pe noul credincios să devină un ucenic al Domnului Isus, om matur în Hristos.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cursul este unul consistent (calitativ și cantitativ) și folosește ca și material bibliografic alte 3 cărți care ar putea fi singure adevărate cursuri de provocare la slujire:
„Evanghelizarea ca mod de viața” de Jim Peterson, „Ucenicii se fac, nu se nasc” de Walter A.
Henrichsen, și „Planul de evanghelizare al Învățătorului” de Robert E. Coleman.
În timp ce treci prin curs vei fi molipsit de o pasiune pentru a aduce pe oameni la Isus!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Dumnezeu și omul interacționează în mod unic pentru a influența procesul de decizie spirituală. Este numai responsabilitatea lui Dumnezeu de a produce prin revelația generală conștiința existenței unei Ființe Supreme. De aici încolo omul care comunică își asumă o responsabilitatea capitală; sarcina de a transmite afirmațiile Evangheliei prin proclamare, în timp ce Dumnezeu, prin Duhul Sfânt, convinge pe ascultător că este păcătos.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    